var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "plugin-settings-wrapper mb-4",
      class: _vm.isActive ? "active" : ""
    },
    [
      _c("div", { staticClass: "plugin-header" }, [
        _c("h5", [_vm._v(_vm._s(_vm.pluginName))])
      ]),
      _c("div", { staticClass: "plugin-content" }, [
        _vm.pluginImgName
          ? _c("img", {
              attrs: {
                src: require(`@/assets/plugins/${_vm.pluginImgName}`),
                alt: ""
              },
              on: { click: _vm.openContextMenu }
            })
          : _c("img", {
              attrs: {
                src: require(`@/assets/plugins/${_vm.imagePlaceholder}`),
                alt: ""
              },
              on: { click: _vm.openContextMenu }
            }),
        _c(
          "div",
          { staticClass: "plugin-footer d-flex justify-content-between" },
          [
            _c(
              "b-button",
              { attrs: { id: "tooltip-button-feedback" + _vm.handle } },
              [_c("b-icon", { attrs: { icon: "question-circle-fill" } })],
              1
            ),
            _c(
              "b-tooltip",
              {
                attrs: {
                  target: "tooltip-button-feedback" + _vm.handle,
                  delay: "400"
                }
              },
              [_c("p", { domProps: { innerHTML: _vm._s(_vm.pluginDesc) } })]
            ),
            _vm.canEdit === true
              ? _c(
                  "b-dropdown",
                  {
                    ref: "context-menu",
                    staticClass: "context-menu",
                    attrs: {
                      "toggle-class": "dot-button",
                      "no-caret": "",
                      dropup: "",
                      "no-flip": "",
                      right: ""
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "button-content",
                          fn: function() {
                            return [
                              _c("b-icon", {
                                attrs: {
                                  icon: "three-dots-vertical",
                                  "aria-hidden": "true",
                                  scale: ".8"
                                }
                              })
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      2405648877
                    )
                  },
                  [
                    _c(
                      "b-dropdown-item",
                      {
                        attrs: {
                          "link-class": "px-3",
                          disabled: !!_vm.isActive
                        },
                        on: {
                          click: function($event) {
                            return _vm.updateActivationState(true)
                          }
                        }
                      },
                      [
                        _vm._v(" " + _vm._s(_vm.$t("button.activate")) + " "),
                        _c("b-icon", {
                          staticClass: "ml-2",
                          attrs: { icon: "check", scale: "1.2" }
                        })
                      ],
                      1
                    ),
                    _c(
                      "b-dropdown-item",
                      {
                        attrs: {
                          "link-class": "px-3",
                          disabled: !_vm.isActive
                        },
                        on: {
                          click: function($event) {
                            return _vm.updateActivationState(false)
                          }
                        }
                      },
                      [
                        _vm._v(" " + _vm._s(_vm.$t("button.deactivate")) + " "),
                        _c("b-icon", {
                          staticClass: "ml-2",
                          attrs: { icon: "slash-circle", scale: ".7" }
                        })
                      ],
                      1
                    ),
                    _vm.hasModalSettings
                      ? _c(
                          "b-dropdown-item",
                          {
                            attrs: {
                              "link-class": "px-3",
                              disabled: !_vm.isActive
                            },
                            on: {
                              click: function($event) {
                                return _vm.$emit("open-modal")
                              }
                            }
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.$t("settings.settings")) + " "
                            ),
                            _c("b-icon", {
                              staticClass: "ml-2",
                              attrs: { icon: "gear-fill", scale: ".7" }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              : _vm._e()
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }