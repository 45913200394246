var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "plugin-settings" },
    [
      _c("plugin-item-list-overview", {
        attrs: {
          isActive: _vm.isActive,
          pluginName: _vm.pluginName,
          pluginDesc: _vm.pluginDesc,
          pluginImgName: _vm.pluginImgName,
          handle: _vm.handle,
          "can-edit": _vm.editPrivilege,
          "has-modal-settings": "true"
        },
        on: {
          "update:isActive": function($event) {
            _vm.isActive = $event
          },
          "update:is-active": function($event) {
            _vm.isActive = $event
          },
          "open-modal": _vm.openModal,
          "update-is-active": _vm.updateActivationState
        }
      }),
      _c(
        "b-modal",
        {
          attrs: {
            id: "plugin-lead-modal",
            "content-class": "plugin-lead-modal plugin-modal",
            size: "xl",
            centered: "",
            "ok-only": "",
            "hide-header-close": "",
            "no-close-on-esc": "",
            "no-close-on-backdrop": ""
          },
          on: { ok: _vm.handleOk },
          scopedSlots: _vm._u([
            {
              key: "modal-title",
              fn: function() {
                return [
                  _c("h3", [
                    _vm._v(
                      _vm._s(_vm.$t("settings.plugins.leadModal.modalTitle"))
                    )
                  ])
                ]
              },
              proxy: true
            },
            {
              key: "modal-footer",
              fn: function({ ok }) {
                return [
                  _c(
                    "b-button",
                    {
                      staticClass: "p-3",
                      attrs: { block: "" },
                      on: {
                        click: function($event) {
                          return ok()
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("button.ok")) + " ")]
                  )
                ]
              }
            }
          ])
        },
        [
          _c(
            "b-row",
            { staticClass: "my-4" },
            [
              _c(
                "b-col",
                { attrs: { cols: "12", "offset-sm": "1", sm: "10" } },
                [
                  _c("p", {
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$t("settings.plugins.leadModal.modalDesc")
                      )
                    }
                  })
                ]
              )
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "my-4" },
            [
              _c(
                "b-col",
                { attrs: { cols: "12", "offset-sm": "1", sm: "10" } },
                [
                  _c("h3", { staticClass: "slide-header" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("settings.plugins.leadModal.headline")) +
                        " "
                    )
                  ])
                ]
              )
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "my-4" },
            [
              _c(
                "b-col",
                { attrs: { cols: "12", "offset-sm": "1", sm: "10" } },
                [
                  !_vm.settingsData.data.length
                    ? _c("div", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("settings.plugins.leadModal.noItems")
                            ) +
                            " "
                        )
                      ])
                    : _c(
                        "div",
                        _vm._l(_vm.settingsData.data, function(rowData, index) {
                          return _c("plugin-lead-modal-row-slide", {
                            key: index,
                            attrs: {
                              configData: rowData,
                              index: index,
                              closeCollapsible: _vm.closeCollapsible,
                              "child-page-slugs": _vm.childPageSlugs
                            },
                            on: {
                              "update-setting-row": _vm.updateSettingRow,
                              "open-rename-modal": _vm.openRenameModal,
                              "delete-item": _vm.handleDeleteItem,
                              "collapsible-closed": function($event) {
                                _vm.closeCollapsible = false
                              }
                            }
                          })
                        }),
                        1
                      )
                ]
              )
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "my-4" },
            [
              _vm.childPageSlugs.length > 0
                ? _c(
                    "b-col",
                    { attrs: { cols: "12", "offset-sm": "1", sm: "10" } },
                    [
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "b-modal",
                              rawName: "v-b-modal.modal-new-lead-modal",
                              modifiers: { "modal-new-lead-modal": true }
                            }
                          ],
                          staticClass: "green-button-transparent mr-3 border-0"
                        },
                        [
                          _c("b-icon", {
                            staticClass: "mr-2 top--3",
                            attrs: {
                              icon: "plus-circle-fill",
                              "aria-hidden": "true",
                              scale: "1"
                            }
                          }),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("settings.plugins.leadModal.newButton")
                              ) +
                              " "
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _c(
                    "b-col",
                    { attrs: { cols: "12", "offset-sm": "1", sm: "10" } },
                    [
                      _c(
                        "b-alert",
                        { attrs: { variant: "warning", show: "" } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "settings.plugins.leadModal.noChildPagesAlert"
                                )
                              ) +
                              " "
                          )
                        ]
                      )
                    ],
                    1
                  )
            ],
            1
          )
        ],
        1
      ),
      _c("b-modal", {
        ref: "modal-new-lead-modal",
        attrs: {
          id: "modal-new-lead-modal",
          "footer-class": "flex-nowrap",
          "no-close-on-backdrop": "",
          centered: ""
        },
        scopedSlots: _vm._u([
          {
            key: "modal-header",
            fn: function() {
              return [
                _c("h3", [
                  _vm._v(
                    _vm._s(_vm.$t("settings.plugins.leadModal.newLeadModal"))
                  )
                ])
              ]
            },
            proxy: true
          },
          {
            key: "default",
            fn: function() {
              return [
                _c("div", [
                  _c(
                    "div",
                    { staticClass: "mt-5 mx-5 mb-3" },
                    [
                      _c("b-form-input", {
                        attrs: { autofocus: "", debounce: "500" },
                        on: {
                          keyup: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.handleAddNewLeadModal.apply(
                              null,
                              arguments
                            )
                          }
                        },
                        model: {
                          value: _vm.newLeadModalHandle,
                          callback: function($$v) {
                            _vm.newLeadModalHandle = $$v
                          },
                          expression: "newLeadModalHandle"
                        }
                      })
                    ],
                    1
                  )
                ])
              ]
            },
            proxy: true
          },
          {
            key: "modal-footer",
            fn: function({ close }) {
              return [
                _c(
                  "b-button",
                  {
                    staticClass: "p-3 border-right-light-grey",
                    attrs: { block: "" },
                    on: {
                      click: function($event) {
                        return close()
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("buttons.cancel")) + " ")]
                ),
                _c(
                  "b-button",
                  {
                    staticClass: "p-3",
                    attrs: { variant: "primary", block: "" },
                    on: { click: _vm.handleAddNewLeadModal }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("buttons.ok")) + " ")]
                )
              ]
            }
          }
        ])
      }),
      _c("b-modal", {
        ref: "modal-rename-lead-modal",
        attrs: {
          id: "modal-rename-lead-modal",
          "footer-class": "flex-nowrap",
          "no-close-on-backdrop": "",
          centered: ""
        },
        scopedSlots: _vm._u([
          {
            key: "modal-header",
            fn: function() {
              return [
                _c("h3", [
                  _vm._v(
                    _vm._s(_vm.$t("settings.plugins.leadModal.renameLeadModal"))
                  )
                ])
              ]
            },
            proxy: true
          },
          {
            key: "default",
            fn: function() {
              return [
                _c("div", [
                  _c(
                    "div",
                    { staticClass: "mt-5 mx-5 mb-3" },
                    [
                      _c("b-form-input", {
                        attrs: { autofocus: "", debounce: "500" },
                        on: {
                          keyup: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.handleRenameLeadModal.apply(
                              null,
                              arguments
                            )
                          }
                        },
                        model: {
                          value: _vm.renameLeadModalName,
                          callback: function($$v) {
                            _vm.renameLeadModalName = $$v
                          },
                          expression: "renameLeadModalName"
                        }
                      })
                    ],
                    1
                  )
                ])
              ]
            },
            proxy: true
          },
          {
            key: "modal-footer",
            fn: function({ close }) {
              return [
                _c(
                  "b-button",
                  {
                    staticClass: "p-3 border-right-light-grey",
                    attrs: { block: "" },
                    on: {
                      click: function($event) {
                        return close()
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("buttons.cancel")) + " ")]
                ),
                _c(
                  "b-button",
                  {
                    staticClass: "p-3",
                    attrs: { variant: "primary", block: "" },
                    on: { click: _vm.handleRenameLeadModal }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("buttons.ok")) + " ")]
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }