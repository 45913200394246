<template>
  <div class="plugin-settings">
    <plugin-item-list-overview
      :isActive.sync="isActive"
      :pluginName="pluginName"
      :pluginDesc="pluginDesc"
      :pluginImgName="pluginImgName"
      :handle="handle"
      :can-edit="editPrivilege"
      has-modal-settings=true
      @open-modal="openModal"
      @update-is-active="updateActivationState"
    ></plugin-item-list-overview>
    <b-modal
      id="plugin-floating-icon-menu"
      content-class="plugin-modal"
      size="lg"
      centered
      ok-only
      hide-header-close
      no-close-on-esc
      no-close-on-backdrop
      @ok="handleOk"
    >
      <template v-slot:modal-title>
        <h3>{{ $t('settings.plugins.floatingIconMenu.modalTitle') }}</h3>
      </template>
      <b-row class="my-4">
        <b-col cols="12" offset-sm="1" sm="10">
          <p v-html="$t('settings.plugins.floatingIconMenu.modalDesc')"></p>
        </b-col>
      </b-row>
      <component
        v-for="(configItem, index) in pluginFieldConfig"
        :key="index"
        :is="configItem.fieldType"
        :handle="configItem.handle"
        :label="configItem.title"
        :desc="configItem.desc"
        :value-from-db="settingsData[configItem.handle]"
        @update-field="updateSettingValue($event, configItem.handle)"
      />
      <template v-slot:modal-footer="{ ok }">
        <b-button
          @click="ok()"
          block
          class="p-3"
        >
          {{ $t('button.ok') }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import CheckboxRow from '@/components/helper/CheckboxRow.vue';
import PluginItemListOverview from '@/components/settings/plugins/PluginItemListOverview.vue';
import InputRow from '@/components/helper/InputRow.vue';
import ImageUploadRow from '@/components/helper/ImageUploadRow.vue';

export default {
  name: 'PluginFloatingIconMenu',
  components: {
    PluginItemListOverview,
    CheckboxRow,
    InputRow,
    ImageUploadRow,
  },
  props: {
    pluginData: {
      type: Object,
      default: null,
    },
    editPrivilege: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      handle: 'plugin_floating_icon_menu',
      pluginName: this.$t('settings.plugins.floatingIconMenu.name'),
      pluginDesc: this.$t('settings.plugins.floatingIconMenu.desc'),
      pluginImgName: 'floating_icon.jpg',
      pluginFieldConfig: [
        {
          title: this.$t('settings.plugins.floatingIconMenu.headlineLabel'),
          desc: this.$t('settings.plugins.floatingIconMenu.headlineDesc'),
          fieldType: 'InputRow',
          handle: 'headline',
        },
        {
          title: this.$t('settings.plugins.floatingIconMenu.textLabel'),
          desc: this.$t('settings.plugins.floatingIconMenu.textDesc'),
          fieldType: 'InputRow',
          handle: 'text',
        },
        {
          title: this.$t('settings.plugins.floatingIconMenu.buttonTextLabel'),
          desc: this.$t('settings.plugins.floatingIconMenu.buttonTextDesc'),
          fieldType: 'InputRow',
          handle: 'buttonText',
        },
        {
          title: this.$t('settings.plugins.floatingIconMenu.buttonLinkLabel'),
          desc: this.$t('settings.plugins.floatingIconMenu.buttonLinkDesc'),
          fieldType: 'InputRow',
          handle: 'buttonLink',
        },
        {
          title: this.$t('settings.plugins.floatingIconMenu.imageLinkLabel'),
          desc: this.$t('settings.plugins.floatingIconMenu.imageLinkDesc'),
          fieldType: 'ImageUploadRow',
          handle: 'imageLink',
        },
        {
          title: this.$t('settings.plugins.floatingIconMenu.hideImageLabel'),
          desc: this.$t('settings.plugins.floatingIconMenu.hideImageDesc'),
          fieldType: 'CheckboxRow',
          handle: 'hideImage',
        },
        {
          title: this.$t('settings.plugins.floatingIconMenu.tooltipLabel'),
          desc: this.$t('settings.plugins.floatingIconMenu.tooltipDesc'),
          fieldType: 'InputRow',
          handle: 'tooltip',
        },
        {
          title: this.$t('settings.plugins.floatingIconMenu.iconLabel'),
          desc: this.$t('settings.plugins.floatingIconMenu.iconDesc'),
          fieldType: 'InputRow',
          handle: 'icon',
        },
        {
          title: this.$t('settings.plugins.floatingIconMenu.buttonTopLabel'),
          desc: this.$t('settings.plugins.floatingIconMenu.buttonTopDesc'),
          fieldType: 'InputRow',
          handle: 'buttonTop',
        },
      ],
      isActive: false,
      settingsData: {
        headline: null,
        text: null,
        buttonText: null,
        buttonLink: null,
        imageLink: {
          url: null,
          width: null,
          height: null,
          focalpoint: {
            x: 50,
            y: 50,
          },
        },
        hideImage: false,
        tooltip: null,
        icon: 'fa-map-marker',
        buttonTop: '100px',
      },
    };
  },
  created() {
    if (this.pluginData) {
      this.isActive = (this.pluginData && 'enabled' in this.pluginData)
        ? this.pluginData.enabled
        : this.isActive;
      this.settingsData = (
        this.pluginData && 'settings' in this.pluginData && Object.keys(this.pluginData.settings).length !== 0
      )
        ? { ...this.pluginData.settings }
        : { ...this.settingsData };
    }
    if (this.settingsData.imageLink?.url) {
      this.settingsData.imageLink.url = this.$makeCloudImage(
        this.settingsData.imageLink.url,
        { width: 450, height: 300 },
        this.settingsData.imageLink,
      );
    }
  },
  methods: {
    openModal() {
      this.$bvModal.show('plugin-floating-icon-menu');
    },
    updateSettingValue(value, handle) {
      if (handle in this.settingsData) {
        this.settingsData[handle] = value;
      }
    },
    handleOk() {
      this.updatePluginData();
    },
    updateActivationState(state) {
      this.isActive = state;
      if (state === true) {
        this.$bvModal.show('plugin-floating-icon-menu');
      }
      this.updatePluginData();
    },
    updateCheckbox(event) {
      this.settingsData.anomymizeIp = event;
    },
    updatePluginData() {
      const pluginDataObject = {
        handle: this.handle,
        enabled: this.isActive,
        settings: this.settingsData,
      };
      this.$emit('update-plugin-data', pluginDataObject);
    },
  },
};
</script>
