<template>
  <div class="plugin-settings">
    <plugin-item-list-overview
      :isActive.sync="isActive"
      :pluginName="pluginName"
      :pluginDesc="pluginDesc"
      :pluginImgName="pluginImgName"
      :handle="handle"
      :can-edit="editPrivilege"
      has-modal-settings=true
      @open-modal="openModal"
      @update-is-active="updateActivationState"
    ></plugin-item-list-overview>
    <b-modal
      id="plugin-linkedin-pixel"
      content-class="plugin-modal"
      size="lg"
      centered
      ok-only
      hide-header-close
      no-close-on-esc
      no-close-on-backdrop
      @ok="handleOk"
    >
      <template v-slot:modal-title>
        <h3>{{ $t('settings.plugins.linkedinPixel.modalTitle') }}</h3>
      </template>
      <b-row class="my-4">
        <b-col cols="12" offset-sm="1" sm="10">
          <p v-html="$t('settings.plugins.linkedinPixel.modalDesc')"></p>
        </b-col>
      </b-row>
      <component
        :is="pluginConfig.fieldType"
        :handle="pluginConfig.handle"
        :label="pluginConfig.title"
        :desc="pluginConfig.desc"
        :value-from-db="settingsData.id"
        @update-field="updateSettingValue($event, 'id')"
      />
      <template v-slot:modal-footer="{ ok }">
        <b-button
          @click="ok()"
          block
          class="p-3"
        >
          {{ $t('button.ok') }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import PluginItemListOverview from '@/components/settings/plugins/PluginItemListOverview.vue';
import InputRow from '@/components/helper/InputRow.vue';

export default {
  name: 'PluginLinkedinPixel',
  components: { PluginItemListOverview, InputRow },
  props: {
    pluginData: {
      type: Object,
      default: null,
    },
    editPrivilege: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      handle: 'plugin_linkedin_pixel',
      pluginName: this.$t('settings.plugins.linkedinPixel.name'),
      pluginDesc: this.$t('settings.plugins.linkedinPixel.desc'),
      pluginImgName: 'linkedin_tag.jpg',
      pluginConfig: {
        title: this.$t('settings.plugins.linkedinPixel.idLabel'),
        desc: this.$t('settings.plugins.linkedinPixel.idDesc'),
        fieldType: 'InputRow',
      },
      isActive: false,
      settingsData: {
        id: null,
      },
    };
  },
  created() {
    if (this.pluginData) {
      this.isActive = (this.pluginData && 'enabled' in this.pluginData)
        ? this.pluginData.enabled
        : this.isActive;
      this.settingsData = (
        this.pluginData && 'settings' in this.pluginData && Object.keys(this.pluginData.settings).length !== 0
      )
        ? { ...this.pluginData.settings }
        : { ...this.settingsData };
    }
  },
  methods: {
    openModal() {
      this.$bvModal.show('plugin-linkedin-pixel');
    },
    updateSettingValue(value, handle) {
      if (handle in this.settingsData) {
        this.settingsData[handle] = value;
      }
    },
    handleOk() {
      this.updatePluginData();
    },
    updateActivationState(state) {
      this.isActive = state;
      if (state === true) {
        this.$bvModal.show('plugin-linkedin-pixel');
      }
      this.updatePluginData();
    },
    updatePluginData() {
      const pluginDataObject = {
        handle: this.handle,
        enabled: this.isActive,
        settings: this.settingsData,
      };
      this.$emit('update-plugin-data', pluginDataObject);
    },
  },
};
</script>
