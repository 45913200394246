var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "plugin-settings" },
    [
      _c("plugin-item-list-overview", {
        attrs: {
          isActive: _vm.isActive,
          pluginName: _vm.pluginName,
          pluginDesc: _vm.pluginDesc,
          pluginImgName: _vm.pluginImgName,
          handle: _vm.handle,
          "can-edit": _vm.editPrivilege,
          "has-modal-settings": "true"
        },
        on: {
          "update:isActive": function($event) {
            _vm.isActive = $event
          },
          "update:is-active": function($event) {
            _vm.isActive = $event
          },
          "open-modal": _vm.openModal,
          "update-is-active": _vm.updateActivationState
        }
      }),
      _c(
        "b-modal",
        {
          attrs: {
            id: "plugin-intro",
            "content-class": "plugin-modal",
            size: "xl",
            centered: "",
            "ok-only": "",
            "hide-header-close": "",
            "no-close-on-esc": "",
            "no-close-on-backdrop": ""
          },
          on: { ok: _vm.handleOk },
          scopedSlots: _vm._u([
            {
              key: "modal-title",
              fn: function() {
                return [
                  _c("h3", [
                    _vm._v(_vm._s(_vm.$t("settings.plugins.intro.modalTitle")))
                  ])
                ]
              },
              proxy: true
            },
            {
              key: "modal-footer",
              fn: function({ ok }) {
                return [
                  _c(
                    "b-button",
                    {
                      staticClass: "p-3",
                      attrs: { block: "" },
                      on: {
                        click: function($event) {
                          return ok()
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("button.ok")) + " ")]
                  )
                ]
              }
            }
          ])
        },
        [
          _c(
            "b-row",
            { staticClass: "my-4" },
            [
              _c(
                "b-col",
                { attrs: { cols: "12", "offset-sm": "1", sm: "10" } },
                [
                  _c("p", {
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$t("settings.plugins.intro.modalDesc")
                      )
                    }
                  })
                ]
              )
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "my-4" },
            [
              _c(
                "b-col",
                { attrs: { cols: "12", "offset-sm": "1", sm: "10" } },
                [
                  _c(
                    "h3",
                    { staticClass: "slide-header" },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("settings.plugins.intro.headline")) +
                          " "
                      ),
                      _c(
                        "b-dropdown",
                        {
                          attrs: {
                            "toggle-class": "dot-button",
                            "no-caret": "",
                            dropup: "",
                            "no-flip": ""
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "button-content",
                              fn: function() {
                                return [
                                  _c("b-icon", {
                                    attrs: {
                                      icon: "three-dots-vertical",
                                      "aria-hidden": "true",
                                      scale: "1.2"
                                    }
                                  })
                                ]
                              },
                              proxy: true
                            }
                          ])
                        },
                        [
                          _c(
                            "b-dropdown-item",
                            {
                              on: {
                                click: function($event) {
                                  return _vm.$bvModal.show("modal-import")
                                }
                              }
                            },
                            [
                              _vm._v(" Import "),
                              _c("b-icon", {
                                attrs: {
                                  icon: "box-arrow-in-down",
                                  "aria-hidden": "true",
                                  scale: ".8"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "b-dropdown-item",
                            {
                              on: {
                                click: function($event) {
                                  return _vm.$bvModal.show("modal-export")
                                }
                              }
                            },
                            [
                              _vm._v(" Export "),
                              _c("b-icon", {
                                attrs: {
                                  icon: "box-arrow-up",
                                  "aria-hidden": "true",
                                  scale: ".8"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              )
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "my-4" },
            [
              _c(
                "b-col",
                { attrs: { cols: "12", "offset-sm": "1", sm: "10" } },
                [
                  !_vm.settingsData.data.length
                    ? _c("div", [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("settings.plugins.intro.noSlides")) +
                            " "
                        )
                      ])
                    : _c(
                        "div",
                        [
                          _vm._l(_vm.settingsData.data, function(
                            rowData,
                            index
                          ) {
                            return _c("plugin-intro-row-slide", {
                              key: index,
                              attrs: {
                                configData: rowData,
                                index: index,
                                closeCollapsible: _vm.closeCollapsible
                              },
                              on: {
                                "update-setting-row": _vm.updateSettingRow,
                                "open-rename-modal": _vm.openRenameModal,
                                "delete-item": _vm.handleDeleteItem,
                                "move-up": _vm.handleMoveUp,
                                "move-down": _vm.handleMoveDown,
                                "collapsible-closed": function($event) {
                                  _vm.closeCollapsible = false
                                }
                              }
                            })
                          }),
                          _c(
                            "div",
                            { staticClass: "mt-4" },
                            [
                              _c(
                                "b-form-checkbox",
                                {
                                  attrs: {
                                    id: "showButtonInNavigation",
                                    name: "showButtonInNavigation",
                                    size: "lg"
                                  },
                                  on: {
                                    input: _vm.updateShowButtonInNavigation
                                  },
                                  model: {
                                    value: _vm.showButtonInNavigation,
                                    callback: function($$v) {
                                      _vm.showButtonInNavigation = $$v
                                    },
                                    expression: "showButtonInNavigation"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "settings.plugins.intro.showButtonInNavi"
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              ),
                              _c("b-form-text", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.$t(
                                      "settings.plugins.intro.showButtonInNaviSubText"
                                    )
                                  )
                                }
                              })
                            ],
                            1
                          )
                        ],
                        2
                      )
                ]
              )
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "my-4" },
            [
              _c(
                "b-col",
                { attrs: { cols: "12", "offset-sm": "1", sm: "10" } },
                [
                  _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "b-modal",
                          rawName: "v-b-modal.modal-new-slide",
                          modifiers: { "modal-new-slide": true }
                        }
                      ],
                      staticClass: "green-button-transparent mr-3 border-0"
                    },
                    [
                      _c("b-icon", {
                        staticClass: "mr-2 top--3",
                        attrs: {
                          icon: "plus-circle-fill",
                          "aria-hidden": "true",
                          scale: "1"
                        }
                      }),
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("settings.plugins.intro.newButton")) +
                          " "
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("b-modal", {
        ref: "modal-new-slide",
        attrs: {
          id: "modal-new-slide",
          "footer-class": "flex-nowrap",
          "no-close-on-backdrop": "",
          centered: ""
        },
        scopedSlots: _vm._u([
          {
            key: "modal-header",
            fn: function() {
              return [
                _c("h3", [
                  _vm._v(_vm._s(_vm.$t("settings.plugins.intro.newSlide")))
                ])
              ]
            },
            proxy: true
          },
          {
            key: "default",
            fn: function() {
              return [
                _c("div", [
                  _c(
                    "div",
                    { staticClass: "mt-5 mx-5 mb-3" },
                    [
                      _c("b-form-input", {
                        attrs: { autofocus: "", debounce: "500" },
                        on: {
                          keyup: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.handleAddNewSlide.apply(null, arguments)
                          }
                        },
                        model: {
                          value: _vm.newSlideHandle,
                          callback: function($$v) {
                            _vm.newSlideHandle = $$v
                          },
                          expression: "newSlideHandle"
                        }
                      })
                    ],
                    1
                  )
                ])
              ]
            },
            proxy: true
          },
          {
            key: "modal-footer",
            fn: function({ close }) {
              return [
                _c(
                  "b-button",
                  {
                    staticClass: "p-3 border-right-light-grey",
                    attrs: { block: "" },
                    on: {
                      click: function($event) {
                        return close()
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("buttons.cancel")) + " ")]
                ),
                _c(
                  "b-button",
                  {
                    staticClass: "p-3",
                    attrs: { variant: "primary", block: "" },
                    on: { click: _vm.handleAddNewSlide }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("buttons.ok")) + " ")]
                )
              ]
            }
          }
        ])
      }),
      _c("b-modal", {
        ref: "modal-rename-slide",
        attrs: {
          id: "modal-rename-slide",
          "footer-class": "flex-nowrap",
          "no-close-on-backdrop": "",
          centered: ""
        },
        scopedSlots: _vm._u([
          {
            key: "modal-header",
            fn: function() {
              return [
                _c("h3", [
                  _vm._v(_vm._s(_vm.$t("settings.plugins.intro.renameHeader")))
                ])
              ]
            },
            proxy: true
          },
          {
            key: "default",
            fn: function() {
              return [
                _c("div", [
                  _c(
                    "div",
                    { staticClass: "mt-5 mx-5 mb-3" },
                    [
                      _c("b-form-input", {
                        attrs: { autofocus: "", debounce: "500" },
                        on: {
                          keyup: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.handleRenameSlide.apply(null, arguments)
                          }
                        },
                        model: {
                          value: _vm.slideHandleForRename,
                          callback: function($$v) {
                            _vm.slideHandleForRename = $$v
                          },
                          expression: "slideHandleForRename"
                        }
                      })
                    ],
                    1
                  )
                ])
              ]
            },
            proxy: true
          },
          {
            key: "modal-footer",
            fn: function({ close }) {
              return [
                _c(
                  "b-button",
                  {
                    staticClass: "p-3 border-right-light-grey",
                    attrs: { block: "" },
                    on: {
                      click: function($event) {
                        return close()
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("buttons.cancel")) + " ")]
                ),
                _c(
                  "b-button",
                  {
                    staticClass: "p-3",
                    attrs: { variant: "primary", block: "" },
                    on: { click: _vm.handleRenameSlide }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("buttons.ok")) + " ")]
                )
              ]
            }
          }
        ])
      }),
      _c("b-modal", {
        ref: "modal-import",
        attrs: {
          id: "modal-import",
          "footer-class": "flex-nowrap",
          "no-close-on-backdrop": "",
          centered: ""
        },
        scopedSlots: _vm._u([
          {
            key: "modal-header",
            fn: function() {
              return [
                _c("h3", [
                  _vm._v(_vm._s(_vm.$t("settings.plugins.intro.importHeader")))
                ])
              ]
            },
            proxy: true
          },
          {
            key: "default",
            fn: function() {
              return [
                _c("div", [
                  _c(
                    "div",
                    { staticClass: "mt-4 mx-5 mb-3" },
                    [
                      _c("p", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$t("settings.plugins.intro.importDesc")
                          )
                        }
                      }),
                      _vm.showImportErrorMsg
                        ? _c("p", { staticClass: "error" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "settings.plugins.intro.importValidationMsg"
                                  )
                                ) +
                                " "
                            )
                          ])
                        : _vm._e(),
                      _c("b-form-textarea", {
                        attrs: { id: "textarea-small", rows: "10" },
                        model: {
                          value: _vm.importData,
                          callback: function($$v) {
                            _vm.importData = $$v
                          },
                          expression: "importData"
                        }
                      })
                    ],
                    1
                  )
                ])
              ]
            },
            proxy: true
          },
          {
            key: "modal-footer",
            fn: function({ close }) {
              return [
                _c(
                  "b-button",
                  {
                    staticClass: "p-3 border-right-light-grey",
                    attrs: { block: "" },
                    on: {
                      click: function($event) {
                        return close()
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("buttons.cancel")) + " ")]
                ),
                _c(
                  "b-button",
                  {
                    staticClass: "p-3",
                    attrs: { variant: "primary", block: "" },
                    on: { click: _vm.importUserData }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("settings.plugins.intro.importButton")) +
                        " "
                    )
                  ]
                )
              ]
            }
          }
        ])
      }),
      _c("b-modal", {
        ref: "modal-export",
        attrs: {
          id: "modal-export",
          "footer-class": "flex-nowrap",
          "no-close-on-backdrop": "",
          centered: ""
        },
        scopedSlots: _vm._u([
          {
            key: "modal-header",
            fn: function() {
              return [
                _c("h3", [
                  _vm._v(_vm._s(_vm.$t("settings.plugins.intro.exportHeader")))
                ])
              ]
            },
            proxy: true
          },
          {
            key: "default",
            fn: function() {
              return [
                _c("div", [
                  _c("div", { staticClass: "mt-5 mx-5 mb-3" }, [
                    _c("div", { staticClass: "mb-2" }, [
                      _vm._v(" Copy to clipboard: "),
                      _c(
                        "span",
                        {
                          staticClass: "copy-data",
                          on: { click: _vm.copyTextToClipboard }
                        },
                        [
                          _c("b-icon", {
                            attrs: {
                              icon: "files",
                              "aria-hidden": "true",
                              scale: "1.2"
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _c("pre", [
                      _vm._v(
                        _vm._s(_vm.settingDataForExport) + "\n            "
                      )
                    ])
                  ])
                ])
              ]
            },
            proxy: true
          },
          {
            key: "modal-footer",
            fn: function({ ok }) {
              return [
                _c(
                  "b-button",
                  {
                    staticClass: "p-3 border-right-light-grey",
                    attrs: { block: "" },
                    on: {
                      click: function($event) {
                        return ok()
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("buttons.ok")) + " ")]
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }