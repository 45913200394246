<template>
  <div class="plugin-settings">
    <plugin-item-list-overview
        :isActive.sync="isActive"
        :pluginName="pluginName"
        :pluginDesc="pluginDesc"
        :pluginImgName="pluginImgName"
        :handle="handle"
        :can-edit="editPrivilege"
        has-modal-settings=true
        @open-modal="openModal"
        @update-is-active="updateActivationState"
    ></plugin-item-list-overview>
    <b-modal
        id="plugin-password-protection"
        content-class="plugin-modal"
        size="lg"
        centered
        ok-only
        hide-header-close
        no-close-on-esc
        no-close-on-backdrop
        @ok="handleOk"
    >
      <template #modal-title>
        <h3>{{ $t('settings.plugins.passwordProtection.modalTitle') }}</h3>
      </template>
      <b-row class="my-4">
        <b-col cols="12" offset-sm="1" sm="10">
          <p v-html="$t('settings.plugins.passwordProtection.modalDesc')"></p>
        </b-col>
      </b-row>
      <b-row class="mb-4">
        <b-col cols="12" offset-sm="1" sm="3">
          <label>{{ passwordConfig.title }}</label>
        </b-col>
        <b-col cols="12" sm="7">
          <b-form-input
            id="hash"
            v-model="settingsData.hash"
            debounce="500"
            type="password"
          ></b-form-input>
          <b-form-text v-html="passwordConfig.desc"></b-form-text>
        </b-col>
      </b-row>
      <component
        v-for="(configItem, index) in pluginFieldConfig"
        :key="index"
        :is="configItem.fieldType"
        :handle="configItem.handle"
        :label="configItem.title"
        :desc="configItem.desc"
        :value-from-db="settingsData[configItem.handle]"
        @update-field="updateSettingValue($event, configItem.handle)"
        @file-removed="updateSettingValue($event, configItem.handle)"
      />
      <template v-slot:modal-footer="{ ok }">
        <b-button
          @click="ok()"
          block
          class="p-3"
        >
          {{ $t('button.ok') }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import CheckboxRow from '@/components/helper/CheckboxRow.vue';
import PluginItemListOverview from '@/components/settings/plugins/PluginItemListOverview.vue';
import InputRow from '@/components/helper/InputRow.vue';
import ImageUploadRow from '@/components/helper/ImageUploadRow.vue';

export default {
  name: 'PluginPasswordProtection',
  components: {
    PluginItemListOverview,
    CheckboxRow,
    InputRow,
    ImageUploadRow,
  },
  props: {
    pluginData: {
      type: Object,
      default: null,
    },
    editPrivilege: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      handle: 'plugin_password_protection',
      pluginName: this.$t('settings.plugins.passwordProtection.name'),
      pluginDesc: this.$t('settings.plugins.passwordProtection.desc'),
      pluginImgName: 'password.jpg',
      pluginFieldConfig: [
        {

        },
        {
          title: this.$t('settings.plugins.passwordProtection.titleLabel'),
          desc: this.$t('settings.plugins.passwordProtection.titleDesc'),
          fieldType: 'InputRow',
          handle: 'title',
        },
        {
          title: this.$t('settings.plugins.passwordProtection.textLabel'),
          desc: this.$t('settings.plugins.passwordProtection.textDesc'),
          fieldType: 'InputRow',
          handle: 'text',
        },
        {
          title: this.$t('settings.plugins.passwordProtection.buttonTextLabel'),
          desc: this.$t('settings.plugins.passwordProtection.buttonTextDesc'),
          fieldType: 'InputRow',
          handle: 'buttonText',
        },
        {
          title: this.$t('settings.plugins.passwordProtection.bgLinkLabel'),
          desc: this.$t('settings.plugins.passwordProtection.bgLinkDesc'),
          fieldType: 'ImageUploadRow',
          handle: 'bgLink',
        },
        {
          title: this.$t('settings.plugins.passwordProtection.colorLabel'),
          desc: this.$t('settings.plugins.passwordProtection.colorDesc'),
          fieldType: 'InputRow',
          handle: 'color',
        },
      ],
      passwordConfig: {
        title: this.$t('settings.plugins.passwordProtection.passwordLabel'),
        desc: this.$t('settings.plugins.passwordProtection.passwordDesc'),
        fieldType: 'InputRow',
        handle: 'hash',
      },
      isActive: false,
      settingsData: {
        hash: null,
        title: null,
        text: null,
        buttonText: null,
        color: null,
        bgLink: null,
      },
      bgLinkObject: {
        url: null,
        width: null,
        height: null,
        focalpoint: {
          x: 50,
          y: 50,
        },
      },
    };
  },
  created() {
    if (this.pluginData) {
      this.isActive = (this.pluginData && 'enabled' in this.pluginData)
        ? this.pluginData.enabled
        : this.isActive;
      this.settingsData = (
        this.pluginData && 'settings' in this.pluginData && Object.keys(this.pluginData.settings).length !== 0
      )
        ? { ...this.pluginData.settings }
        : { ...this.settingsData };
      // if password is set override the password with a default string '****!'
      if (this.pluginData.settings.hash) {
        this.settingsData.hash = '****!';
      }
      if (this.pluginData.settings.bgLink) {
        this.settingsData.bgLink = {
          ...this.bgLinkObject,
          url: this.pluginData.settings.bgLink,
        };
      } else {
        this.settingsData.bgLink = {
          ...this.bgLinkObject,
        };
      }
    }
  },
  methods: {
    openModal() {
      this.$bvModal.show('plugin-password-protection');
    },
    updateSettingValue(value, handle) {
      this.settingsData[handle] = value;
    },
    handleOk() {
      this.updatePluginData();
    },
    updateActivationState(state) {
      this.isActive = state;
      if (state === true) {
        this.$bvModal.show('plugin-password-protection');
      }
      this.updatePluginData();
    },
    updateCheckbox(event) {
      this.settingsData.anomymizeIp = event;
    },
    updatePluginData() {
      const pluginDataObject = {
        handle: this.handle,
        enabled: this.isActive,
        settings: this.settingsData,
      };
      this.$emit('update-plugin-data', pluginDataObject);
    },
  },
};
</script>
