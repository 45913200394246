<template>
  <div class="plugin-settings">
    <plugin-item-list-overview
        :isActive.sync="isActive"
        :pluginName="pluginName"
        :pluginDesc="pluginDesc"
        :pluginImgName="pluginImgName"
        :handle="handle"
        :can-edit="editPrivilege"
        has-modal-settings=true
        @open-modal="openModal"
        @update-is-active="updateActivationState"
    ></plugin-item-list-overview>
    <b-modal
        id="plugin-variables"
        content-class="plugin-modal"
        size="xl"
        centered
        ok-only
        hide-header-close
        no-close-on-esc
        no-close-on-backdrop
        @ok="handleOk"
    >
      <template v-slot:modal-title>
        <h3>{{ $t('settings.plugins.variables.modalTitle') }}</h3>
      </template>
      <b-row class="my-4">
        <b-col cols="12" offset-sm="1" sm="10">
          <p v-html="$t('settings.plugins.variables.modalDesc')"></p>
        </b-col>
      </b-row>
      <b-row class="my-4">
        <b-col cols="12" offset-sm="1" sm="10">
          <h3 class="slide-header">
            {{ $t('settings.plugins.variables.headline') }}
            <b-dropdown
              toggle-class="dot-button"
              no-caret
              dropup
              no-flip
            >
              <template #button-content>
                <b-icon icon="three-dots-vertical" aria-hidden="true" scale="1.2"></b-icon>
              </template>
              <b-dropdown-item
                @click="$bvModal.show('modal-variables-import')"
              >
                Import
                <b-icon icon="box-arrow-in-down" aria-hidden="true" scale=".8"></b-icon>
              </b-dropdown-item>
              <b-dropdown-item
                @click="$bvModal.show('modal-variables-export')"
              >
                Export
                <b-icon icon="box-arrow-up" aria-hidden="true" scale=".8"></b-icon>
              </b-dropdown-item>
            </b-dropdown>
          </h3>
        </b-col>
      </b-row>
      <b-row class="my-4">
        <b-col cols="12" offset-sm="1" sm="10">
          <div v-if="!settingsData.length">
            {{ $t('settings.plugins.variables.noSlides') }}
          </div>
          <plugin-variables-row-slide
            v-for="(rowData, index) in settingsData"
            :key="index"
            :configData="rowData"
            :index="index"
            @update-setting-row="updateSettingRow"
            @open-rename-modal="openRenameModal"
            @delete-item="handleDeleteItem"
            @move-up="handleMoveUp"
            @move-down="handleMoveDown"
          ></plugin-variables-row-slide>
        </b-col>
      </b-row>
      <b-row class="my-4">
        <b-col cols="12" offset-sm="1" sm="10">
          <b-button
            v-b-modal.modal-new-variable-slide
            class="green-button-transparent mr-3 border-0"
          >
            <b-icon
              icon="plus-circle-fill"
              class="mr-2 top--3"
              aria-hidden="true"
              scale="1"
            ></b-icon>
            {{ $t('settings.plugins.variables.newButton') }}
          </b-button>
        </b-col>
      </b-row>
      <template v-slot:modal-footer="{ ok }">
        <b-button
          @click="ok()"
          block
          class="p-3"
        >
          {{ $t('button.ok') }}
        </b-button>
      </template>
    </b-modal>
    <b-modal
      id="modal-new-variable-slide"
      ref="modal-new-variable-slide"
      footer-class="flex-nowrap"
      no-close-on-backdrop
      centered
    >
      <template #modal-header>
        <h3>{{ $t('settings.plugins.variables.newSlide') }}</h3>
      </template>

      <template #default>
        <div>
          <div class="mt-5 mx-5 mb-3">
            <b-form-input
              autofocus
              v-model="newSlideHandle"
              @input="slugifyHandle"
              @keyup.enter="handleAddNewSlide"
            >
            </b-form-input>
          </div>
        </div>
      </template>

      <template #modal-footer="{ close }">
        <b-button
          class="p-3 border-right-light-grey"
          block
          @click="close()"
        >
          {{ $t('buttons.cancel') }}
        </b-button>
        <b-button
          variant="primary"
          class="p-3"
          block
          @click="handleAddNewSlide"
        >
          {{ $t('buttons.ok') }}
        </b-button>
      </template>
    </b-modal>
    <b-modal
      id="modal-rename-variable-slide"
      ref="modal-rename-variable-slide"
      footer-class="flex-nowrap"
      no-close-on-backdrop
      centered
    >
      <template #modal-header>
        <h3>{{ $t('settings.plugins.variables.renameHeader') }}</h3>
      </template>

      <template #default>
        <div>
          <div class="mt-5 mx-5 mb-3">
            <b-form-input
              autofocus
              v-model="slideVarForRename"
              @input="slugifyRename"
              @keyup.enter="handleRenameSlide"
            >
            </b-form-input>
          </div>
        </div>
      </template>

      <template #modal-footer="{ close }">
        <b-button
          class="p-3 border-right-light-grey"
          block
          @click="close()"
        >
          {{ $t('buttons.cancel') }}
        </b-button>
        <b-button
          variant="primary"
          class="p-3"
          block
          @click="handleRenameSlide"
        >
          {{ $t('buttons.ok') }}
        </b-button>
      </template>
    </b-modal>
    <b-modal
      id="modal-variables-import"
      ref="modal-variables-import"
      footer-class="flex-nowrap"
      no-close-on-backdrop
      centered
    >
      <template #modal-header>
        <h3>{{ $t('settings.plugins.variables.importHeader') }}</h3>
      </template>

      <template #default>
        <div>
          <div class="mt-4 mx-5 mb-3">
            <p v-if="showImportErrorMsg" class="error">
              {{ $t('settings.plugins.variables.importValidationMsg') }}
            </p>
            <b-form-textarea
              id="textarea-small"
              v-model="importData"
              rows="10"
            ></b-form-textarea>
            <p v-html="$t('settings.plugins.variables.importDesc')"></p>
          </div>
        </div>
      </template>

      <template #modal-footer="{ close }">
        <b-button
          class="p-3 border-right-light-grey"
          block
          @click="close()"
        >
          {{ $t('buttons.cancel') }}
        </b-button>
        <b-button
          variant="primary"
          class="p-3"
          block
          @click="importUserData"
        >
          {{ $t('settings.plugins.variables.importButton') }}
        </b-button>
      </template>
    </b-modal>
    <b-modal
      id="modal-variables-export"
      ref="modal-variables-export"
      footer-class="flex-nowrap"
      no-close-on-backdrop
      centered
    >
      <template #modal-header>
        <h3>{{ $t('settings.plugins.variables.exportHeader') }}</h3>
      </template>

      <template #default>
        <div>
          <div class="mt-5 mx-5 mb-3">
            <div class="mb-2">
              Copy to clipboard:
              <span class="copy-data" @click="copyTextToClipboard">
                <b-icon icon="files" aria-hidden="true" scale="1.2"></b-icon>
              </span>
            </div>
            <pre>
{{ settingDataForExport }}
            </pre>
          </div>
        </div>
      </template>

      <template #modal-footer="{ ok }">
        <b-button
          class="p-3 border-right-light-grey"
          block
          @click="ok()"
        >
          {{ $t('buttons.ok') }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import slugify from '@sindresorhus/slugify';

export default {
  name: 'PluginVariables',
  components: {
    PluginItemListOverview: () => import('@/components/settings/plugins/PluginItemListOverview.vue'),
    // CheckboxRow: () => import('@/components/helper/CheckboxRow.vue'),
    // TextareaRow: () => import('@/components/helper/TextareaRow.vue'),
    PluginVariablesRowSlide: () => import('@/components/settings/plugins//PluginVariablesRowSlide.vue'),
  },
  props: {
    pluginData: {
      type: Object,
      default: null,
    },
    editPrivilege: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    settingDataForExport() {
      return this.settingsData;
    },
  },
  data() {
    return {
      handle: 'plugin_variables',
      pluginName: this.$t('settings.plugins.variables.name'),
      pluginDesc: this.$t('settings.plugins.variables.desc'),
      pluginImgName: 'variables.jpg',
      pluginFieldConfig: [
        {
          title: this.$t('settings.plugins.variables.idLabel'),
          desc: this.$t('settings.plugins.variables.idDesc'),
          fieldType: 'TextareaRow',
          handle: 'json',
          placeholder: '',
        },
      ],
      isActive: false,
      newSlideHandle: '',
      slideVarForRename: null,
      renameIndex: null,
      settingsData: [],
      importData: null,
      isCollapseOpen: false,
      showImportErrorMsg: false,
    };
  },
  created() {
    if (this.pluginData) {
      this.isActive = (this.pluginData && 'enabled' in this.pluginData)
        ? this.pluginData.enabled
        : this.isActive;
      this.settingsData = (
        this.pluginData && 'settings' in this.pluginData && Array.isArray(this.pluginData.settings) && this.pluginData.settings.length !== 0
      )
        ? cloneDeep(this.pluginData.settings)
        : [...this.settingsData];
    }
  },
  methods: {
    slugifyRename() {
      this.slideVarForRename = slugify(this.slideVarForRename);
    },
    slugifyHandle() {
      this.newSlideHandle = slugify(this.newSlideHandle);
    },
    handleMoveUp(index) {
      const temp = this.settingsData[index];
      this.settingsData.splice(index, 1);
      this.settingsData.splice(index - 1, 0, temp);
    },
    handleMoveDown(index) {
      const temp = this.settingsData[index];
      this.settingsData.splice(index, 1);
      this.settingsData.splice(index + 1, 0, temp);
    },
    importUserData() {
      this.showImportErrorMsg = false;
      try {
        const validationDataArray = JSON.parse(this.importData);
        console.log('validationDataArray: ', validationDataArray);
        // check if array
        if (!Array.isArray(validationDataArray)) {
          throw new Error('not an array');
        }
        validationDataArray.forEach((object) => {
          if (
            !object.hasOwnProperty('var')
            || !object.hasOwnProperty('text')
          ) {
            throw new Error('property is missing');
          }
        });
        this.settingsData = validationDataArray;
        this.importData = null;
        this.$bvModal.hide('modal-variables-import');
      } catch (e) {
        console.error('Import error: ', e);
        this.showImportErrorMsg = true;
      }
    },
    openRenameModal(index) {
      this.renameIndex = index;
      this.slideVarForRename = this.settingsData[this.renameIndex].var;
      this.$bvModal.show('modal-rename-variable-slide');
    },
    handleRenameSlide() {
      this.$bvModal.hide('modal-rename-variable-slide');
      this.settingsData[this.renameIndex].var = this.slideVarForRename;
      this.slideVarForRename = null;
      this.renameIndex = null;
      this.updatePluginData();
    },
    openModal() {
      this.$bvModal.show('plugin-variables');
    },
    updateSettingValue(value, handle) {
      if (handle in this.settingsData) {
        this.settingsData[handle] = value;
      }
    },
    copyTextToClipboard() {
      navigator.clipboard.writeText(JSON.stringify(this.settingsData));
      // display a message to the user for 2 seconds
      this.$bvToast.toast(this.$t('settings.plugins.variables.textCopied'), {
        title: this.$t('settings.plugins.variables.textCopiedTitle'),
        variant: 'success',
        solid: true,
        autoHideDelay: 2000,
      });
    },
    handleDeleteItem(index) {
      this.settingsData.splice(index, 1);
      this.updatePluginData();
    },
    handleOk() {
      this.updatePluginData();
    },
    handleAddNewSlide() {
      this.$bvModal.hide('modal-new-variable-slide');
      // update the settings array
      this.settingsData.push({
        var: this.newSlideHandle,
        text: '',
      });
      this.newSlideHandle = '';
    },
    updateSettingRow(settingsRow, index) {
      this.settingsData[index] = settingsRow;
      this.updatePluginData();
    },
    updateActivationState(state) {
      this.isActive = state;
      if (state === true) {
        this.$bvModal.show('plugin-variables');
      }
      this.updatePluginData();
    },
    updatePluginData() {
      const pluginDataObject = {
        handle: this.handle,
        enabled: this.isActive,
        settings: this.settingsData,
      };
      this.$emit('update-plugin-data', pluginDataObject);
    },
  },
};
</script>

<style lang="scss" scoped>
#plugin-variables .slide-header /deep/ .btn-secondary.dot-button {
  background-color: transparent;
  border: none;
  color: #bbb;
  position: relative;
  top: -3px;
}

/deep/ .dropdown-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 1px #e0e2e4;

  svg {
    padding-left: 5px;
    margin-left: 5px;
  }
}

.copy-data {
  cursor: pointer;

  /deep/ svg:hover {
    transition: all .2s;
    transform: scale(1.2);
  }
}

.error {
  color: red !important;
  padding: 5px 15px;
  border: solid 1px red;
}
</style>
