var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "plugin-settings" },
    [
      _c("plugin-item-list-overview", {
        attrs: {
          isActive: _vm.isActive,
          pluginName: _vm.pluginName,
          pluginDesc: _vm.pluginDesc,
          pluginImgName: _vm.pluginImgName,
          handle: _vm.handle,
          "can-edit": _vm.editPrivilege,
          "has-modal-settings": "true"
        },
        on: {
          "update:isActive": function($event) {
            _vm.isActive = $event
          },
          "update:is-active": function($event) {
            _vm.isActive = $event
          },
          "open-modal": _vm.openModal,
          "update-is-active": _vm.updateActivationState
        }
      }),
      _c(
        "b-modal",
        {
          attrs: {
            id: "plugin-plausible",
            "content-class": "plugin-modal",
            size: "lg",
            centered: "",
            "ok-only": "",
            "hide-header-close": "",
            "no-close-on-esc": "",
            "no-close-on-backdrop": ""
          },
          on: { ok: _vm.handleOk },
          scopedSlots: _vm._u([
            {
              key: "modal-title",
              fn: function() {
                return [
                  _c("h3", [
                    _vm._v(
                      _vm._s(_vm.$t("settings.plugins.plausible.modalTitle"))
                    )
                  ])
                ]
              },
              proxy: true
            },
            {
              key: "modal-footer",
              fn: function({ ok }) {
                return [
                  _c(
                    "b-button",
                    {
                      staticClass: "p-3",
                      attrs: { block: "" },
                      on: {
                        click: function($event) {
                          return ok()
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("button.ok")) + " ")]
                  )
                ]
              }
            }
          ])
        },
        [
          _c(
            "b-row",
            { staticClass: "my-4" },
            [
              _c(
                "b-col",
                { attrs: { cols: "12", "offset-sm": "1", sm: "10" } },
                [
                  _c("p", {
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$t("settings.plugins.plausible.modalDesc")
                      )
                    }
                  })
                ]
              )
            ],
            1
          ),
          _c(_vm.pluginConfig.fieldType, {
            tag: "component",
            attrs: {
              handle: _vm.pluginConfig.handle,
              label: _vm.pluginConfig.title,
              desc: _vm.pluginConfig.desc,
              "value-from-db": _vm.settingsData.siteName
            },
            on: {
              "update-field": function($event) {
                return _vm.updateSettingValue($event, "siteName")
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }