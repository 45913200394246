<template>
  <div class="plugin-settings-wrapper mb-4" :class="isActive ? 'active' : ''">
    <div class="plugin-header">
      <h5>{{ pluginName }}</h5>
    </div>
    <div class="plugin-content">
      <img
        v-if="pluginImgName"
        :src="require(`@/assets/plugins/${pluginImgName}`)"
        alt=""
        @click="openContextMenu"
      >
      <img
        v-else
        :src="require(`@/assets/plugins/${imagePlaceholder}`)"
        alt=""
        @click="openContextMenu"
      >
      <div class="plugin-footer d-flex justify-content-between">
        <b-button :id="'tooltip-button-feedback' + handle">
          <b-icon icon="question-circle-fill"></b-icon>
        </b-button>
        <b-tooltip
          :target="'tooltip-button-feedback' + handle"
          delay=400>
          <p v-html="pluginDesc"></p>
        </b-tooltip>
        <b-dropdown
          v-if="canEdit === true"
          class="context-menu"
          ref="context-menu"
          toggle-class="dot-button"
          no-caret
          dropup
          no-flip
          right
        >
          <template #button-content>
            <b-icon icon="three-dots-vertical" aria-hidden="true" scale=".8"></b-icon>
          </template>
          <b-dropdown-item
            link-class="px-3"
            @click="updateActivationState(true)"
            :disabled="!!isActive"
          >
            {{ $t('button.activate') }}
            <b-icon class="ml-2" icon="check" scale="1.2"></b-icon>
          </b-dropdown-item>
          <b-dropdown-item
            link-class="px-3"
            @click="updateActivationState(false)"
            :disabled="!isActive"
          >
            {{ $t('button.deactivate') }}
            <b-icon class="ml-2" icon="slash-circle" scale=".7"></b-icon>
          </b-dropdown-item>
          <b-dropdown-item
            v-if="hasModalSettings"
            link-class="px-3"
            @click="$emit('open-modal')"
            :disabled="!isActive"
          >
            {{ $t('settings.settings') }}
            <b-icon class="ml-2" icon="gear-fill" scale=".7"></b-icon>
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PluginItemListOverview',
  data: () => ({
    imagePlaceholder: 'plugin-placeholder.png',
  }),
  props: [
    'isActive',
    'pluginName',
    'pluginDesc',
    'hasModalSettings',
    'pluginImgName',
    'handle',
    'canEdit',
  ],
  methods: {
    updateActivationState(state) {
      this.$emit('update-is-active', state);
    },
    openContextMenu() {
      this.$refs['context-menu'].show();
    },
  },
};
</script>

<style scoped lang="scss">
img {
  width: 100%;
  cursor: pointer;
}

/deep/ button, /deep/ button:hover, /deep/ button:active, /deep/ button:focus {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}

.plugin-settings-wrapper.active {
  .plugin-header h5 {
    color: $webmag-green-light;
  }

  .plugin-content {
    border-color: $webmag-green-light;
  }
}

.plugin-header {
  h5 {
    font-size: 15px;
    line-height: 1.4;
    font-weight: 600;
    color: #fff;
    font-family: "Source Sans Pro", sans-serif;
  }
}

.plugin-settings {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

.plugin-content {
  border: 2px solid transparent;
}

.plugin-footer {
  background-color: #53575b;
}

/deep/ .dropdown-menu {
  min-width: 130px;
}

/deep/ .dropdown-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;

  &:hover {
    background-color: #f4f4f4;
  }
}
</style>
