<template>
  <div class="plugin-settings">
    <plugin-item-list-overview
      :isActive.sync="isActive"
      :pluginName="pluginName"
      :pluginDesc="pluginDesc"
      :pluginImgName="pluginImgName"
      :handle="handle"
      :can-edit="editPrivilege"
      has-modal-settings=true
      @open-modal="openModal"
      @update-is-active="updateActivationState"
    ></plugin-item-list-overview>
    <b-modal
      id="plugin-lead-modal"
      content-class="plugin-lead-modal plugin-modal"
      size="xl"
      centered
      ok-only
      hide-header-close
      no-close-on-esc
      no-close-on-backdrop
      @ok="handleOk"
    >
      <template v-slot:modal-title>
        <h3>{{ $t('settings.plugins.leadModal.modalTitle') }}</h3>
      </template>
      <b-row class="my-4">
        <b-col cols="12" offset-sm="1" sm="10">
          <p v-html="$t('settings.plugins.leadModal.modalDesc')"></p>
        </b-col>
      </b-row>
      <b-row class="my-4">
        <b-col cols="12" offset-sm="1" sm="10">
          <h3 class="slide-header">
            {{ $t('settings.plugins.leadModal.headline') }}
          </h3>
        </b-col>
      </b-row>
      <b-row class="my-4">
        <b-col cols="12" offset-sm="1" sm="10">
          <div v-if="!settingsData.data.length">
            {{ $t('settings.plugins.leadModal.noItems') }}
          </div>
          <div v-else>
            <plugin-lead-modal-row-slide
              v-for="(rowData, index) in settingsData.data"
              :key="index"
              :configData="rowData"
              :index="index"
              :closeCollapsible="closeCollapsible"
              :child-page-slugs="childPageSlugs"
              @update-setting-row="updateSettingRow"
              @open-rename-modal="openRenameModal"
              @delete-item="handleDeleteItem"
              @collapsible-closed="closeCollapsible = false"
            ></plugin-lead-modal-row-slide>
          </div>
        </b-col>
      </b-row>
      <b-row class="my-4">
        <b-col v-if="childPageSlugs.length > 0" cols="12" offset-sm="1" sm="10">
          <b-button
            v-b-modal.modal-new-lead-modal
            class="green-button-transparent mr-3 border-0"
          >
            <b-icon
              icon="plus-circle-fill"
              class="mr-2 top--3"
              aria-hidden="true"
              scale="1"
            ></b-icon>
            {{ $t('settings.plugins.leadModal.newButton') }}
          </b-button>
        </b-col>
        <b-col v-else cols="12" offset-sm="1" sm="10">
          <b-alert variant="warning" show>
            {{ $t('settings.plugins.leadModal.noChildPagesAlert') }}
          </b-alert>
        </b-col>
      </b-row>
      <template v-slot:modal-footer="{ ok }">
        <b-button
          @click="ok()"
          block
          class="p-3"
        >
          {{ $t('button.ok') }}
        </b-button>
      </template>
    </b-modal>

    <!-- New modal for adding a new lead modal -->
    <b-modal
      id="modal-new-lead-modal"
      ref="modal-new-lead-modal"
      footer-class="flex-nowrap"
      no-close-on-backdrop
      centered
    >
      <template #modal-header>
        <h3>{{ $t('settings.plugins.leadModal.newLeadModal') }}</h3>
      </template>

      <template #default>
        <div>
          <div class="mt-5 mx-5 mb-3">
            <b-form-input
              autofocus
              debounce="500"
              v-model="newLeadModalHandle"
              @keyup.enter="handleAddNewLeadModal"
            >
            </b-form-input>
          </div>
        </div>
      </template>

      <template #modal-footer="{ close }">
        <b-button
          class="p-3 border-right-light-grey"
          block
          @click="close()"
        >
          {{ $t('buttons.cancel') }}
        </b-button>
        <b-button
          variant="primary"
          class="p-3"
          block
          @click="handleAddNewLeadModal"
        >
          {{ $t('buttons.ok') }}
        </b-button>
      </template>
    </b-modal>
    <b-modal
      id="modal-rename-lead-modal"
      ref="modal-rename-lead-modal"
      footer-class="flex-nowrap"
      no-close-on-backdrop
      centered
    >
      <template #modal-header>
        <h3>{{ $t('settings.plugins.leadModal.renameLeadModal') }}</h3>
      </template>

      <template #default>
        <div>
          <div class="mt-5 mx-5 mb-3">
            <b-form-input
              autofocus
              debounce="500"
              v-model="renameLeadModalName"
              @keyup.enter="handleRenameLeadModal"
            >
            </b-form-input>
          </div>
        </div>
      </template>

      <template #modal-footer="{ close }">
        <b-button
          class="p-3 border-right-light-grey"
          block
          @click="close()"
        >
          {{ $t('buttons.cancel') }}
        </b-button>
        <b-button
          variant="primary"
          class="p-3"
          block
          @click="handleRenameLeadModal"
        >
          {{ $t('buttons.ok') }}
        </b-button>
      </template>
    </b-modal>
    <!-- Add other modals (import, export) here -->
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';

export default {
  name: 'PluginLeadModal',
  components: {
    PluginItemListOverview: () => import('@/components/settings/plugins/PluginItemListOverview.vue'),
    PluginLeadModalRowSlide: () => import('@/components/settings/plugins/PluginLeadModalRowSlide.vue'),
  },
  props: {
    pluginData: {
      type: Object,
      default: null,
    },
    editPrivilege: {
      type: Boolean,
      default: false,
    },
    childPageSlugs: {
      type: Array,
    },
  },
  data() {
    return {
      handle: 'plugin_lead_modal',
      pluginName: this.$t('settings.plugins.leadModal.name'),
      pluginDesc: this.$t('settings.plugins.leadModal.desc'),
      pluginImgName: 'lead-modal.jpg',
      isActive: false,
      settingsData: {
        data: [],
      },
      newLeadModalHandle: '',
      closeCollapsible: false,
      renameLeadModalName: '',
      renameLeadModalIndex: null,
    };
  },
  created() {
    if (this.pluginData) {
      this.isActive = this.pluginData.enabled || this.isActive;
      this.settingsData = this.pluginData.settings && this.pluginData.settings.data
        ? cloneDeep(this.pluginData.settings)
        : this.settingsData;
    }
    console.log('child page slugs', this.childPageSlugs);
  },
  methods: {
    openModal() {
      this.$bvModal.show('plugin-lead-modal');
    },
    updateActivationState(state) {
      this.isActive = state;
      if (state === true) {
        this.$bvModal.show('plugin-lead-modal');
      }
      this.updatePluginData();
    },
    handleOk() {
      this.updatePluginData();
    },
    updatePluginData() {
      const pluginDataObject = {
        handle: this.handle,
        enabled: this.isActive,
        settings: this.settingsData,
      };
      this.$emit('update-plugin-data', pluginDataObject);
    },
    handleAddNewLeadModal() {
      this.$bvModal.hide('modal-new-lead-modal');
      this.settingsData.data.push({
        name: this.newLeadModalHandle,
        showOnSlug: [],
        slug: null,
        oneTime: true,
        timeout: 0,
        scrollPercent: null,
        size: 'modal-md',
        sizeTablet: null,
        sizeMobile: null,
        center: false,
      });
      this.newLeadModalHandle = '';
      this.updatePluginData();
    },
    updateSettingRow(settingsRow, index) {
      this.settingsData.data[index] = settingsRow;
      this.updatePluginData();
    },
    handleDeleteItem(index) {
      this.settingsData.data.splice(index, 1);
      this.updatePluginData();
    },
    openRenameModal(index) {
      this.renameLeadModalIndex = index;
      this.renameLeadModalName = this.settingsData.data[index].name;
      this.$bvModal.show('modal-rename-lead-modal');
    },
    handleRenameLeadModal() {
      if (this.renameLeadModalIndex !== null && this.renameLeadModalName.trim() !== '') {
        this.settingsData.data[this.renameLeadModalIndex].name = this.renameLeadModalName.trim();
        this.updatePluginData();
        this.$bvModal.hide('modal-rename-lead-modal');
        this.renameLeadModalIndex = null;
        this.renameLeadModalName = '';
      }
    },
    handleSelectUpdate(value) {
      // Handle the selected value update here
      console.log('Selected value:', value);
    },
  },
};
</script>

<style lang="scss" scoped>
#plugin-lead-modal .slide-header /deep/ .btn-secondary.dot-button {
  background-color: transparent;
  border: none;
  color: #bbb;
  position: relative;
  top: -3px;
}

/deep/ .dropdown-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 1px #e0e2e4;

  svg {
    padding-left: 5px;
    margin-left: 5px;
  }
}

.copy-data {
  cursor: pointer;

  /deep/ svg:hover {
    transition: all .2s;
    transform: scale(1.2);
  }
}

.error {
  color: red !important;
  padding: 5px 15px;
  border: solid 1px red;
}
</style>
